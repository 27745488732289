export const categoryValues = [
  {key: 'companion', text: 'Companion', value: 'Companion'},
  {key: 'necramech', text: 'Necramech', value: 'Necramech'},
  {key: 'warframe', text: 'Warframe', value: 'Warframe'},
  {key: 'weapon', text: 'Weapon', value:  'Weapon'},
  {key: 'vehicle', text: 'Vehicle', value: 'Vehicle'}
]

export const slotValues = [
  {key: 'amp', text: 'Amp', value: 'Amp'},
  {key: 'arch-gun', text: 'Arch-Gun', value: 'Arch-Gun'},
  {key: 'arch-melee', text: 'Arch-Melee', value: 'Arch-Melee'},
  {key: 'archwing', text: 'Archwing', value: 'Archwing'},
  {key: 'companion', text: 'Companion', value: 'Companion'},
  {key: 'hound weapon', text: 'Hound Weapon', value: 'Hound Weapon'},
  {key: 'k-drive', text: 'K-Drive', value: 'K-Drive'},
  {key: 'melee', text: 'Melee', value: 'Melee'},
  {key: 'operator', text: 'Operator', value: 'Operator'},
  {key: 'primary', text: 'Primary', value: 'Primary'},
  {key: 'robotic', text: 'Robotic', value: 'Robotic'},
  {key: 'secondary', text: 'Secondary', value: 'Secondary'},
]

export const typeValues = [
  {key: 'amp', text: 'Amp', value: 'Amp'},
  {key: 'arch-gun', text: 'Arch-Gun', value: 'Arch-Gun'},
  {key: 'arm-cannon', text: 'Arm-Cannon', value: 'Arm-Cannon'},
  {key: 'blade-and-whip', text: 'Blade and Whip', value: 'Blade and Whip'},
  {key: 'bow', text: 'Bow', value: 'Bow'},
  {key: 'claws', text: 'Claws', value: 'Claws'},
  {key: 'crossbow', text: 'Crossbow', value: 'Crossbow'},
  {key: 'dagger', text: 'Dagger', value: 'Dagger'},
  {key: 'dual-daggers', text: 'Dual Daggers', value: 'Dual Daggers'},
  {key: 'dual-pistols', text: 'Dual Pistols', value: 'Dual Pistols'},
  {key: 'dual-shotguns', text: 'Dual Shotguns', value: 'Dual Shotguns'},
  {key: 'dual-swords', text: 'Dual Swords', value: 'Dual Swords'},
  {key: 'fist', text: 'Fist', value: 'Fist'},
  {key: 'glaive', text: 'Glaive', value: 'Glaive'},
  {key: 'gunblade', text: 'Gunblade', value: 'Gunblade'},
  {key: 'hammer', text: 'Hammer', value: 'Hammer'},
  {key: 'heavy-blade', text: 'Heavy Blade', value: 'Heavy Blade'},
  {key: 'heavy-scythe', text: 'Heavy Scythe', value: 'Heavy Scythe'},
  {key: 'hound', text: 'Hound', value: 'Hound'},
  {key: 'kavat', text: 'Kavat', value: 'Kavat'},
  {key: 'kitgun', text: 'Kitgun', value: 'Kitgun'},
  {key: 'kubrow', text: 'Kubrow', value: 'Kubrow'},
  {key: 'launcher', text: 'Launcher', value: 'Launcher'},
  {key: 'machete', text: 'Machete', value: 'Machete'},
  {key: 'moa', text: 'Moa', value: 'Moa'},
  {key: 'nikana', text: 'Nikana', value: 'Nikana'},
  {key: 'nunchaku', text: 'Nunchaku', value: 'Nunchaku'},
  {key: 'pistol', text: 'Pistol', value: 'Pistol'},
  {key: 'polearm', text: 'Polearm', value: 'Polearm'},
  {key: 'predasite', text: 'Predasite', value: 'Predasite'},
  {key: 'rapier', text: 'Rapier', value: 'Rapier'},
  {key: 'rifle', text: 'Rifle', value: 'Rifle'},
  {key: 'sentinel', text: 'Sentinel', value: 'Sentinel'},
  {key: 'scythe', text: 'Scythe', value: 'Scythe'},
  {key: 'shotgun', text: 'Shotgun', value: 'Shotgun'},
  {key: 'shotgun-sidearm', text: 'Shotgun Sidearm', value: 'Shotgun Sidearm'},
  {key: 'sniper-rifle', text: 'Sniper Rifle', value: 'Sniper Rifle'},
  {key: 'sparring', text: 'Sparring', value: 'Sparring'},
  {key: 'speargun', text: 'Speargun', value: 'Speargun'},
  {key: 'staff', text: 'Staff', value: 'Staff'},
  {key: 'sword', text: 'Sword', value: 'Sword'},
  {key: 'sword-and-shield', text: 'Sword and Shield', value: 'Sword and Shield'},
  {key: 'thrown', text: 'Thrown', value: 'Thrown'},
  {key: 'tonfa', text: 'Tonfa', value: 'Tonfa'},
  {key: 'two-handed-nikana', text: 'Two-Handed Nikana', value: 'Two-Handed Nikana'},
  {key: 'warfan', text: 'Warfan', value: 'Warfan'},
  {key: 'whip', text: 'Whip', value: 'Whip'},
  {key: 'zaw', text: 'Zaw', value: 'Zaw'}
]

export const trueFalseValues = [
  {key: false, text: 'No', value: false, selected: true},
  {key: true, text: 'Yes', value: true}
]

export const mrValues = [
  {key: '0', text: 'MR0', value: 0},
  {key: '1', text: 'MR1', value: 1},
  {key: '2', text: 'MR2', value: 2},
  {key: '3', text: 'MR3', value: 3},
  {key: '4', text: 'MR4', value: 4},
  {key: '5', text: 'MR5', value: 5},
  {key: '6', text: 'MR6', value: 6},
  {key: '7', text: 'MR7', value: 7},
  {key: '8', text: 'MR8', value: 8},
  {key: '9', text: 'MR9', value: 9},
  {key: '10', text: 'MR10', value: 10},
  {key: '11', text: 'MR11', value: 11},
  {key: '12', text: 'MR12', value: 12},
  {key: '13', text: 'MR13', value: 13},
  {key: '14', text: 'MR14', value: 14},
  {key: '15', text: 'MR15', value: 15},
  {key: '16', text: 'MR16', value: 16}
]

export const masteryValues = [
  {key: '3000', text: '3000', value: 3000},
  {key: '4000', text: '4000', value: 4000},
  {key: '6000', text: '6000', value: 6000},
  {key: '8000', text: '8000', value: 8000}
]

export const sourceValues = [
  {key: 'trading', text: 'Available via trading', value: 'Available via trading'},
  {key: 'baro', text: "Baro Ki'Teer", value: "Baro Ki'Teer"},
  {key: 'bio-lab', text: 'Clan - Bio Lab', value: 'Clan - Bio Lab'},
  {key: 'chem-lab', text: 'Clan - Chemical Lab', value: 'Clan - Chemical Lab'},
  {key: 'energy-lab', text: 'Clan - Energy Lab', value: 'Clan - Energy Lab'},
  {key: 'tenno-lab', text: 'Clan - Tenno Lab', value: 'Clan - Tenno Lab'},
  {key: 'egg-incubation', text: 'Egg Incubation', value: 'Egg Incubation'},
  {key: 'dna-incubation', text: 'Kavat DNA Incubation', value: 'Kavat DNA Incubation'},
  {key: 'kuva-lich', text: 'Kuva Lich', value: 'Kuva Lich'},
  {key: 'login', text: 'Login Reward (Random)', value: 'Login Reward (Random)'},
  {key: 'market', text: 'Market', value: 'Market'},
  {key: 'relics', text: 'Relics', value: 'Relics'},
  {key: 'sisters', text: 'Sisters of Parvos', value: 'Sisters of Parvos'},
  {key: 'arbiters', text: 'Syndicate - Arbiters of Hexis', value: 'Syndicate - Arbiters of Hexis'},
  {key: 'simaris', text: 'Syndicate - Cephalon Simaris', value: 'Syndicate - Cephalon Simaris'},
  {key: 'suda', text: 'Syndicate - Cephalon Suda', value: 'Syndicate - Cephalon Suda'},
  {key: 'entrati', text: 'Syndicate - Entrati', value: 'Syndicate - Entrati'},
  {key: 'necraloid', text: 'Syndicate - Necraloid', value: 'Syndicate - Necraloid'},
  {key: 'loka', text: 'Syndicate - New Loka', value: 'Syndicate - New Loka'},
  {key: 'ostron', text: 'Syndicate - Ostrons', value: 'Syndicate - Ostrons'},
  {key: 'perrin', text: 'Syndicate - Perrin Sequence', value: 'Syndicate - Perrin Sequence'},
  {key: 'veil', text: 'Syndicate - Red Veil', value: 'Syndicate - Red Veil'},
  {key: 'solaris-united', text: 'Syndicate - Solaris United', value: 'Syndicate - Solaris United'},
  {key: 'meridian', text: 'Syndicate - Steel Meridian', value: 'Syndicate - Steel Meridian'},
  {key: 'quills', text: 'Syndicate - The Quills', value: 'Syndicate - The Quills'},
  {key: 'ventkids', text: 'Syndicate - Ventkids', value: 'Syndicate - Ventkids'},
  {key: 'vox-solaris', text: 'Syndicate - Vox Solaris', value: 'Syndicate - Vox Solaris'}
]
