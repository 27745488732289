export const adjectives = [
  "abundant",
  "acidic",
  "adorable",
  "adventurous",
  "aggressive",
  "agreeable",
  "alert",
  "alive",
  "ambitious",
  "amused",
  "ancient",
  "angry",
  "annoyed",
  "annoying",
  "anxious",
  "arrogant",
  "ashamed",
  "attractive",
  "average",
  "awful",
  "bad",
  "bald",
  "beautiful",
  "better",
  "bewildered",
  "big",
  "billions",
  "bitter",
  "bloody",
  "blue",
  "blushing",
  "bored",
  "brainy",
  "brave",
  "breakable",
  "breezy",
  "brief",
  "bright",
  "broad",
  "bumpy",
  "busy",
  "calm",
  "careful",
  "cautious",
  "charming",
  "cheerful",
  "chilly",
  "clean",
  "clear",
  "clever",
  "cloudy",
  "clumsy",
  "cold",
  "colorful",
  "colossal",
  "combative",
  "comfortable",
  "concerned",
  "condemned",
  "confused",
  "cool",
  "cooperative",
  "courageous",
  "crashing",
  "crazy",
  "creepy",
  "crooked",
  "crowded",
  "cruel",
  "cuddly",
  "curious",
  "curved",
  "cute",
  "damaged",
  "damp",
  "dangerous",
  "dark",
  "dazzling",
  "dead",
  "deafening",
  "deep",
  "defeated",
  "defiant",
  "delicious",
  "delightful",
  "depressed",
  "determined",
  "different",
  "difficult",
  "dirty",
  "disgusted",
  "disgusting",
  "distinct",
  "disturbed",
  "divergent",
  "dizzy",
  "doubtful",
  "drab",
  "dry",
  "dull",
  "eager",
  "early",
  "easy",
  "echoing",
  "elated",
  "elegant",
  "embarrassed",
  "enchanting",
  "encouraging",
  "energetic",
  "enough",
  "enthusiastic",
  "envious",
  "evil",
  "excited",
  "expensive",
  "exuberant",
  "faint",
  "fair",
  "faithful",
  "famous",
  "fancy",
  "fantastic",
  "fast",
  "few",
  "fierce",
  "filthy",
  "fine",
  "fit",
  "flabby",
  "flaky",
  "flat",
  "fluffy",
  "foolish",
  "fragile",
  "frail",
  "frantic",
  "freezing",
  "fresh",
  "friendly",
  "frightened",
  "full",
  "funny",
  "future",
  "gentle",
  "gifted",
  "gigantic",
  "glamorous",
  "gleaming",
  "glorious",
  "good",
  "gorgeous",
  "graceful",
  "gray",
  "greasy",
  "great",
  "green",
  "grieving",
  "grotesque",
  "grumpy",
  "hallowed",
  "handsome",
  "happy",
  "harsh",
  "healthy",
  "helpful",
  "helpless",
  "high",
  "hilarious",
  "hissing",
  "hollow",
  "homeless",
  "homely",
  "horrible",
  "hot",
  "howling",
  "huge",
  "hundreds",
  "hungry",
  "hurt",
  "icy",
  "ill",
  "immense",
  "important",
  "impossible",
  "incalculable",
  "inexpensive",
  "innocent",
  "inquisitive",
  "itchy",
  "jealous",
  "jittery",
  "jolly",
  "joyous",
  "juicy",
  "kind",
  "large",
  "late",
  "lazy",
  "lemon",
  "light",
  "limited",
  "little",
  "lively",
  "lonely",
  "long",
  "loose",
  "loud",
  "lovely",
  "low",
  "lucky",
  "magnificent",
  "mammoth",
  "mango",
  "many",
  "massive",
  "mealy",
  "melodic",
  "melted",
  "microscopic",
  "millions",
  "miniature",
  "misty",
  "modern",
  "moldy",
  "most",
  "motionless",
  "muddy",
  "muscular",
  "mushy",
  "mysterious",
  "narrow",
  "nasty",
  "naughty",
  "nervous",
  "nice",
  "noisy",
  "numerous",
  "nutritious",
  "nutty",
  "obedient",
  "obnoxious",
  "odd",
  "old",
  "open",
  "orange",
  "outrageous",
  "outstanding",
  "panicky",
  "perfect",
  "petite",
  "pitiful",
  "plain",
  "pleasant",
  "plump",
  "poised",
  "polite",
  "poor",
  "powerful",
  "precious",
  "prehistoric",
  "prickly",
  "proud",
  "puny",
  "purple",
  "purring",
  "putrid",
  "puzzled",
  "quaint",
  "quick",
  "quiet",
  "rancid",
  "rapid",
  "rapping",
  "raspy",
  "real",
  "red",
  "refined",
  "relieved",
  "repulsive",
  "rhythmic",
  "rich",
  "ripe",
  "rotten",
  "rough",
  "round",
  "salmon",
  "salty",
  "savory",
  "scarce",
  "scary",
  "scrawny",
  "screeching",
  "scruffy",
  "selfish",
  "shaggy",
  "shallow",
  "shapely",
  "sharp",
  "shiny",
  "short",
  "shrilling",
  "shy",
  "silly",
  "skinny",
  "sleepy",
  "slimy",
  "slippery",
  "slow",
  "small",
  "smiling",
  "smoggy",
  "some",
  "sore",
  "sour",
  "sparkling",
  "sparse",
  "spicy",
  "splendid",
  "spoiled",
  "spotless",
  "square",
  "squeaking",
  "stale",
  "steep",
  "sticky",
  "stocky",
  "stormy",
  "straight",
  "strange",
  "strong",
  "stupid",
  "substantial",
  "successful",
  "super",
  "sweet",
  "swift",
  "talented",
  "tall",
  "tame",
  "tangy",
  "tart",
  "tasteless",
  "tasty",
  "teeny",
  "tender",
  "tense",
  "terrible",
  "thankful",
  "thoughtful",
  "thoughtless",
  "thousands",
  "thundering",
  "tight",
  "tinkling",
  "tiny",
  "tired",
  "tough",
  "troubled",
  "ugliest",
  "ugly",
  "uneven",
  "unimportant",
  "uninterested",
  "unkempt",
  "unsightly",
  "unusual",
  "upset",
  "uptight",
  "vast",
  "victorious",
  "vivacious",
  "wailing",
  "wandering",
  "warm",
  "weak",
  "weary",
  "wet",
  "whining",
  "whispering",
  "white",
  "wicked",
  "wide",
  "wide-eyed",
  "wild",
  "witty",
  "wonderful",
  "wooden",
  "worried",
  "worrisome",
  "wrong",
  "yellow",
  "young",
  "yummy",
  "zany",
  "zealous",
]
